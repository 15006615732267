<template>
  <div class="home">
    <loader v-if="pages == '' || !pages"></loader>
    <div v-for="(page, index) in pages" :key="index"
        v-if="trimName(page.name) == route
        && route != 'cartdetail'
        && route != 'favourite'
        && route != 'checkout'
        && route != 'about_us'">
          <div v-for="section in page.section">
            <banner v-if="section.type == 'Banner'" :item="section"/>
            <categories v-if="section.type == 'categories'" :item="section"/>
            <videoo v-if="section.type == 'videoo'" :item="section"/>
            <about v-if="section.type == 'about'" :item="section"/>
            <carousel v-if="section.type == 'Full Banner'" :item="section"/>
            <grids v-if="section.type == 'grids'" :item="section"/>
          </div>
    </div>
    <div v-for="(page, index) in pages" :key="index"
        v-if="route == 'about_us'">
          <aboutus v-if="page.name == 'about_us'" :items="page" />
    </div>
    <div v-if="route == 'cartdetail'">
      <cartDetails/>
    </div>
    <div v-if="route == 'myaccount'">
      <myAccount/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  components:{
    swiper: () => import('../components/Swiper'),
    banner: () => import('../components/Banner'),
    videoo: () => import('../components/Video'),
    about: () => import('../components/About'),
    categories: () => import('../components/Categories'),
    carousel: () => import('../components/Carousel'),
    grids: () => import('../components/Grid'),
    aboutus: () => import('../views/About'),
    cartDetails: () => import('../views/cartDetail.vue'),
    myAccount: () => import('../views/myAccount.vue'),
    loader: () => import('../components/loader.vue'),
  },
  data() {
    return {
      pages: false,
      route: false,
      scrollValue: 0,
      user: false,
      ids: false,
      ids1: false,
      userInfo:{}
    };
  },
  watch: {
    "$route.params": {
      handler(newValue, preValue) {
        this.initPage();
        this.setState(preValue);
        // this.vueAnalytics(newValue)
      },
      immediate: true
    }
  },
  methods:{
    initPage() {
      if (localStorage.getItem("pages")) {
        this.pages = JSON.parse(localStorage.getItem("pages"));
      }
      //this.pages = false;
      this.route = this.$route.params.route;
      this.ids = false;
      this.ids1 = false;
      this.vueAnalytics(this.route)
      if (this.$route.params.id) this.ids = this.$route.params.id;
      if (this.$route.params.id1) this.ids1 = this.$route.params.id1;
      var filters = [
        {
            match_phrase: {
                event: this.$store.state.event
            }
        }
      ]
      axios
        .post(
           this.$store.state.api + "theme/_search",
            {
              from: 0,
              size: 5000,
              query: {
                bool: {
                  must: filters
                } 
              }
            }
        ).then(response => {
            this.pages = {}
            var data = response.data.hits.hits
            for(var ind in data){
                this.pages[ind] = data[ind]._source
            }
            // console.log(this.pages)
        })
    },
    vueAnalytics(route){
      console.log(route)
      this.$ga.page({
        page: '/page/'+ route,
        title: route,
        location: window.location.href,
      })
    },
    setState(pre) {
      if (pre) {
        localStorage.setItem("lastState", this.$route.path);
      }
    },

    trimName(name) {
      if (name)
        return name
          .replace(new RegExp(" ", "g"), "_")
          .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
          .toLowerCase();
    },

    handleScroll(e) {
      var newValue = window.pageYOffset;

      if (this.scrollValue - newValue < 0) {
      } else if (this.scrollValue - newValue > 0) {
      }

      this.scrollValue = newValue;
      if (
        document.getElementById("main") &&
        this.scrollValue >= 650 &&
        document.getElementById("main").classList.length == 1
      ) {
        document.getElementById("main").classList.add("flex-main-fixed");
      } else if (
        document.getElementById("main") &&
        this.scrollValue < 640 &&
        document.getElementById("main").classList.length == 2
      ) {
        document.getElementById("main").classList.remove("flex-main-fixed");
      }
    },

  },

  mounted(){
    this.$store.dispatch("Store");
    this.userInfo = this.$store.state.customer
  },

  created(){
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

}
</script>
